import React, { useContext } from "react";

import css from "./Sidebar.module.css";
import { UserContext } from "../../Context/UserContext";
import { ClinicContext } from "../../Context/ClinicContext";
import SmallCalendar from "../Schedule/SmallCalendar";

export const ScheduleSidebar = () => {
  const { userData } = useContext(UserContext);
  const { setView, view } = useContext(ClinicContext);

  if (userData) {
    return (
      <div className={css.sidebar}>
        <div className={css.sidebarWrapper}>
          <div className={css.sidebarMenu}>
            <ul className={css.viewOptions}>
              <button
                className={
                  view === "day" ? css.viewButtonActive : css.viewButton
                }
                onClick={() => setView("day")}
              >
                day
              </button>
              {/* <button className={css.viewButton}>week</button> */}
              <button
                className={
                  view === "month" ? css.viewButtonActive : css.viewButton
                }
                onClick={() => setView("month")}
              >
                month
              </button>
            </ul>
            <SmallCalendar />
          </div>
          <div
            className={`copyright ${
              userData.staff ? css.sidebarMenu : css.copyright
            } `}
          >
            mairini.com © 2023
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
