import React, { useState, useContext } from "react";
import dayjs from "dayjs";

import css from "./DayView.module.css";
import { ClinicContext } from "../../Context/ClinicContext";
import { UserContext } from "../../Context/UserContext";
import OutsideAlerter from "../../Utils/OutsideAlerter";

export default function DayView() {
  const { user } = useContext(UserContext);
  const { daySelected, appointmentsDay } = useContext(ClinicContext);
  const [showSelectedAppointment, setShowSelectedAppointment] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(false);

  let dayArray = [];
  for (var i = 1; i <= 24; i++) {
    dayArray.push(i + ":00");
    dayArray.push(i + ":30");
  }
  // dayArray.push(12 + ":30 am");
  // for (var i = 1; i <= 11; i++) {
  //   dayArray.push(i + ":00 am");
  //   dayArray.push(i + ":30 am");
  // }
  // dayArray.push(12 + ":00 pm");
  // for (var i = 1; i <= 11; i++) {
  //   dayArray.push(i + ":00 pm");
  //   dayArray.push(i + ":30 pm");
  // }
  // dayArray.push(12 + ":00 am");

  const Event = ({ row }) => {
    if (
      appointmentsDay
        .map((e) => dayArray.indexOf(dayjs(e.dtStart).format("HH:mm")))
        .indexOf(row) >= 0
    ) {
      let evt =
        appointmentsDay[
          appointmentsDay
            .map((e) => dayArray.indexOf(dayjs(e.dtStart).format("HH:mm")))
            .indexOf(row)
        ];
      return (
        <>
          <div
            className={css.cell}
            onClick={() => {
              setSelectedAppointment(evt);
              setShowSelectedAppointment(true);
            }}
          >
            <div
              className={
                evt.status === "confirmado" ? css.eventConfirmed : css.event
              }
              style={{
                gridRow: `${
                  dayArray.indexOf(dayjs(evt.dtStart).format("HH:mm")) + 1
                }`,
                gridColumn: `${2}`,
              }}
            >
              {evt.description}
            </div>
          </div>
        </>
      );
    } else {
      return <div className={css.cell}></div>;
    }
  };

  if (user) {
    return (
      <div className={css.day}>
        <span className={css.timezone}>GMT-03</span>
        <div className={`${css.title} `}>
          <p className={css.day_title}>{dayjs(daySelected).format("dd")}</p>
          <p className={css.day_number}>{dayjs(daySelected).format("DD")}</p>
        </div>
        {dayArray.map((hour, row) => (
          <React.Fragment key={row}>
            <p className={css.hour}>{hour}</p>
            {appointmentsDay ? (
              <Event row={row} />
            ) : (
              <div className={css.cell}></div>
            )}
          </React.Fragment>
        ))}

        {showSelectedAppointment && (
          <OutsideAlerter setMenuButton={setShowSelectedAppointment}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setShowSelectedAppointment(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody}>
                <h3>{selectedAppointment.description}</h3>
                <div className={css.schedule}>
                  <p>Nome: {selectedAppointment.userName}</p>
                  <p>Email: {selectedAppointment.userEmail}</p>
                  <p>Médico: {selectedAppointment.proName}</p>
                  <p>Dia: {dayjs(daySelected).format("DD/MM/YY")}</p>
                  <p>Início: {selectedAppointment.start}</p>
                  <p>
                    Término: {dayjs(selectedAppointment.dtEnd).format("HH:mm")}
                  </p>
                  <p>Cofirmado? {selectedAppointment.status}</p>
                </div>

                <div className={css.footer}></div>
              </div>
            </div>
          </OutsideAlerter>
        )}
      </div>
    );
  } else {
    return <></>;
  }
}
