import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import css from "./Home.module.css";
import Head from "../Utils/Head";
import { ClinicContext } from "../Context/ClinicContext";
import OutsideAlerter from "../Utils/OutsideAlerter";
import { Input } from "./Forms/Input";

export const Home = () => {
  const {
    daySelected,
    users,
    appointmentsDay,
    waitingList,
    selectedAppointment,
    setSelectedAppointment,
  } = useContext(ClinicContext);
  const navigate = useNavigate();
  const [showSelectedAppointment, setShowSelectedAppointment] = useState(false);

  function handleAttend(appointment) {
    setSelectedAppointment(appointment);
    navigate(`/user/${appointment.userId}/data`);
  }

  function getWaitingTime(arrived) {
    let startList = dayjs().format("H:mm").split(":");
    let arrivedList = arrived.split(":");
    let minutes1 = Number(startList[0]) * 60 + Number(startList[1]);
    let minutes2 = Number(arrivedList[0]) * 60 + Number(arrivedList[1]);
    let watingHours = (minutes1 - minutes2) / 60;
    let watingHoursList = String(watingHours).split(".");
    return (
      Number(watingHoursList[0]) +
      "h " +
      Math.round(
        Number(watingHoursList[1] ? "0." + watingHoursList[1] : 0.00000001) * 60
      ) +
      "min"
    );
  }

  function getStyle(status) {
    switch (status) {
      case "não confirmado":
        return css.cardItemOut;
      case "cancelado pelo usuário":
        return css.cardItemOut;
      case "cancelado pelo profissional":
        return css.cardItemOut;
      case "não compareceu":
        return css.cardItemOut;
      case "remarcado":
        return css.cardItemOut;
      case "aguardando":
        return css.cardItem;
      case "em atendimento":
        return css.cardItem;
      case "atendido":
        return css.cardItemOk;
      case "confirmado":
        return css.cardItemConfirmed;
      default:
        return;
    }
  }

  return (
    <>
      <Head title="Home" description="Home - Mairini" />
      <h4>Bem vindo ao Mairini Profissionais</h4>
      <div className={css.container}>
        <div className={css.card}>
          <h4>Usuários</h4>
          <Input placeholder="Pesquisar..." />
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Nascimento</th>
                <th>CPF</th>
              </tr>
            </thead>
            <tbody>
              {users.map((u, i) => (
                <tr
                  className={css.cardItem}
                  key={u.id}
                  onClick={() => {
                    navigate(`/user/${u.id}/today`);
                  }}
                >
                  <td>{u.displayName}</td>
                  <td>DN</td>
                  <td>000.000.000-00</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* <div className={css.card}>
          <h4>Consultas de {dayjs(daySelected).format("DD MMM YY")}</h4>
          {appointmentsDay.map((app) => (
            <p
              key={app.id}
              className={getStyle(app.status)}
              onClick={() => {
                setSelectedAppointment(app);
                setShowSelectedAppointment(true);
              }}
            >
              {app.userName} | {dayjs(app.dtStart).format("HH:mm")} |{" "}
              {app.status}
            </p>
          ))}
        </div>

        <div className={css.card}>
          <h4>Consultas de {dayjs(daySelected).format("DD MMM YY")}</h4>
          <table>
            <thead>
              <tr>
                <th>Paciente</th>
                <th>Hora</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {appointmentsDay.map((app) => (
                <tr
                  key={app.id}
                  className={getStyle(app.status)}
                  onClick={() => {
                    setSelectedAppointment(app);
                    setShowSelectedAppointment(true);
                  }}
                >
                  <td>{app.userName}</td>
                  <td>{dayjs(app.dtStart).format("HH:mm")}</td>
                  <td>{app.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}

        {/* <div className={css.card}>
          <h4>Espera</h4>
          <table>
            <thead>
              <tr>
                <th>Hora</th>
                <th>Chegada</th>
                <th>Paciente</th>
                <th>Tempo de espera</th>
                <th>Atender</th>
              </tr>
            </thead>
            <tbody>
              {waitingList.map((app) => (
                <tr key={app.id}>
                  <td>{app.start}</td>
                  <td>{app.arrived}</td>
                  <td>{app.userName}</td>
                  <td>{getWaitingTime(app.arrived)}</td>
                  <td>
                    <button
                      className="defaultButton"
                      onClick={() => handleAttend(app)}
                    >
                      Atender
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}

        {showSelectedAppointment && (
          <OutsideAlerter setMenuButton={setShowSelectedAppointment}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setShowSelectedAppointment(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalButton}>
                {selectedAppointment.status === "confirmado" && (
                  <button
                    className="greenButton"
                    onClick={() => handleAttend(selectedAppointment)}
                  >
                    Iniciar Atendendimento
                  </button>
                )}
              </div>
              <div className={css.modalBody}>
                <h3>{selectedAppointment.description}</h3>
                <div className={css.schedule}>
                  <p>Nome: {selectedAppointment.userName}</p>
                  <p>Email: {selectedAppointment.userEmail}</p>
                  <p>Médico: {selectedAppointment.proName}</p>
                  <p>Dia: {dayjs(daySelected).format("DD/MM/YY")}</p>
                  <p>
                    Início: {dayjs(selectedAppointment.dtStart).format("HH:mm")}
                  </p>
                  <p>
                    Término: {dayjs(selectedAppointment.dtEnd).format("HH:mm")}
                  </p>
                  <div className={css.status}>
                    <p>Status: {selectedAppointment.status}</p>
                  </div>
                </div>

                <div className={css.footer}></div>
              </div>
            </div>
          </OutsideAlerter>
        )}
      </div>
    </>
  );
};
