import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import css from "./UserPage.module.css";
import Head from "../../Utils/Head";
import { ClinicContext } from "../../Context/ClinicContext";
import {
  createRecordFirebase,
  getRecordsFirebase,
  getUserByIdFirebase,
} from "../../Utils/firebaseClinic";
import { newId } from "../../Utils/newId";
import { UserContext } from "../../Context/UserContext";

export const UserRecord = () => {
  const { selectedPatient, setSelectedPatient } = useContext(ClinicContext);
  const { userData } = useContext(UserContext);
  const [content, setContent] = useState("");
  const [records, setRecords] = useState([]);
  const [showNewRecord, setShowNewRecord] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (!selectedPatient.displayName) {
      getUserByIdFirebase(params.id).then(function (result) {
        setSelectedPatient(result[0]);
      });
    }
    if (selectedPatient.displayName) {
      getRecordsFirebase(selectedPatient).then(function (result) {
        setRecords(result);
      });
    }
  }, [selectedPatient, params.id]);

  function saveRecord() {
    let newContent = {
      id: newId(),
      date: dayjs().format("DD/MM/YY"),
      userId: selectedPatient.id,
      proId: userData.id,
      proEmail: userData.email,
      proName: userData.displayName,
      content: content,
    };
    createRecordFirebase(newContent);
    setShowNewRecord(false);
  }

  return (
    <>
      <section>
        <Head title="User" description="User - Mairini" />
        <h4>Anamnese</h4>

        <button className="greenButton" onClick={() => setShowNewRecord(true)}>
          + Nova Anamnese
        </button>
      </section>

      <section className={css.recordCard}>
        {records.map((rec, i) => (
          <div key={rec.id} className={css.recordHeader}>
            <p
              onClick={
                showContent === i
                  ? () => setShowContent(false)
                  : () => setShowContent(i)
              }
            >
              {rec.date} - {rec.proName} (Médico)
            </p>
            {showContent === i && (
              <div className={css.recordContent}>
                <div>Clinica Mairini</div>
                <div>Nome: {selectedPatient.displayName}</div>
                <div>Data de Nascimento: XX/XX/XX</div>
                <div>{rec.content}</div>
              </div>
            )}
          </div>
        ))}
      </section>

      {showNewRecord && (
        <div className={css.modal}>
          <header className={css.modalHeader}>
            <span
              onClick={() => setShowNewRecord(false)}
              className={`material-icons ${css.materialIcon} ${css.materialButton}`}
            >
              close
            </span>
          </header>
          <div className={css.modalBody}>
            <h3>Anamnese</h3>

            <h4>Indentificação</h4>
            <p>Nome: {selectedPatient.displayName}</p>
            <div className={css.inLine}>
              <p>Data de nascimento: XXX</p>
              <p>Idade: XXX</p>
              <p>Sexo: XXX</p>
            </div>

            <h4>Evolução</h4>
            <textarea
              rows="50"
              onClick={(e) => e.preventDefault()}
              onChange={(e) => setContent(e.target.value)}
              value={content}
              className={css.textarea}
              maxLength="250"
            />
          </div>
          <div className={css.footer}>
            <button className="saveButton" onClick={() => saveRecord()}>
              Salvar
            </button>
          </div>
        </div>
      )}
    </>
  );
};
