import React from "react";
import { Link } from "react-router-dom";

import css from "./Login.module.css";
import { UserContext } from "../../Context/UserContext";
import useForm from "../../Hooks/useForm";
import Button from "../Forms/Button";
import { Input } from "../Forms/Input";
import Error from "../../Utils/Error";
import Head from "../../Utils/Head";

export const LoginCreate = () => {
  const { userRegister, loading, error } = React.useContext(UserContext);
  const name = useForm();
  const email = useForm("email");
  const password = useForm("password");
  const confirmPassword = useForm("confirmPassword");
  const [alertFirst, setAlertFirst] = React.useState("");
  const [alertEmail, setAlertEmail] = React.useState("");
  const [alertValid, setAlertValid] = React.useState("");

  function handleValidation() {
    let formIsValid = true;
    if (!name.value) {
      formIsValid = false;
      setAlertFirst("You need a first name");
    } else {
      setAlertFirst("");
    }
    if (!email.value) {
      formIsValid = false;
      setAlertEmail("You need a email");
    } else {
      setAlertEmail("");
    }
    if (!password.value) {
      formIsValid = false;
      setAlertValid("You need a password");
    } else {
      setAlertValid("");
    }
    return formIsValid;
  }

  async function handleSubmit() {
    userRegister(name.value, email.value, password.value);
  }

  return (
    <>
      <section className={css.login}>
        <div></div>
        <div className={css.forms}>
          <Head title="Create your Mairini Account" />
          <h1 className="title">Criar conta no Mairini</h1>
          {alertFirst && <p className={css.error}>{alertFirst}</p>}
          {alertEmail && <p className={css.error}>{alertEmail}</p>}
          {alertValid && <p className={css.error}>{alertValid}</p>}
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleValidation() && handleSubmit();
            }}
          >
            <Input label="Nome completo" type="text" name="name" {...name} />
            <Input label="Email" type="email" name="email" {...email} />
            <Input
              label="Senha"
              type="password"
              name="password"
              {...password}
            />
            <Input
              label="Confirme a Senha"
              type="password"
              name="confirmPassword"
              {...confirmPassword}
            />
            {loading ? (
              <Button disabled>Carregando...</Button>
            ) : (
              <Button>Enviar</Button>
            )}
            <Error error={error} />
          </form>
          <p></p>
        </div>
      </section>
    </>
  );
};
