import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import css from "./AdminPage.module.css";
import Head from "../../Utils/Head";
import { newId } from "../../Utils/newId";
import { Input } from "../Forms/Input";
import { InputArea } from "../Forms/InputArea";
import { ClinicContext } from "../../Context/ClinicContext";
import { storage } from "../../firebaseConfig";
import { createPostListsFirebase } from "../../Utils/firebaseClinic";
import dayjs from "dayjs";

export const NewPractice = () => {
  const { actTechsList, practiceList, setPracticeList } =
    useContext(ClinicContext);
  const navigate = useNavigate();
  const [state, setState] = useState("");
  const [pillar, setPillar] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [image, setImage] = useState("");
  const [content, setContent] = useState("");
  const [imageUpload, setImageUpload] = React.useState(null);
  const [progress, setProgress] = React.useState(null);

  const slugify = (text) =>
    text
      .toString()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-");

  function handleSubmit() {
    let updatedPracticeList = [];

    if (practiceList) {
      updatedPracticeList = [...practiceList];
    }

    const newPost = {
      id: newId(),
      date: dayjs().valueOf(),
      pillar: pillar,
      title: title,
      slug: slug,
      image: image,
      content: content,
    };

    updatedPracticeList.push(newPost);
    setPracticeList(updatedPracticeList);

    createPostListsFirebase(actTechsList, updatedPracticeList);
    alert("practice created");
    navigate("/admin");
  }

  const uploadImage = () => {
    const name = newId();
    let imageRef = ref(storage, `postImages/${state.pillar}/theory/${name}`);

    const uploadTask = uploadBytesResumable(imageRef, imageUpload);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log(downloadURL);
          setImage(downloadURL);
          alert("Image Uploaded");
        });
      }
    );
  };

  return (
    <div>
      <Head title="Admin" description={"Admin - Mairini"} />
      <h4>New Practice</h4>
      <p>Date: {dayjs().format("DD/MM/YY")}</p>
      <p>Pillar:</p>
      <div>
        <label className={css.pillar}>
          mind
          <input
            type="checkbox"
            checked={pillar === "mind" ? true : false}
            onChange={() => {
              setPillar("mind");
            }}
          />
          <span className={css.checkmark}></span>
        </label>
        <label className={css.pillar}>
          move
          <input
            type="checkbox"
            checked={pillar === "move" ? true : false}
            onChange={() => {
              setPillar("move");
            }}
          />
          <span className={css.checkmark}></span>
        </label>
        <label className={css.pillar}>
          nutri
          <input
            type="checkbox"
            checked={pillar === "nutri" ? true : false}
            onChange={() => {
              setPillar("nutri");
            }}
          />
          <span className={css.checkmark}></span>
        </label>
        <label className={css.pillar}>
          outra
          <input
            type="checkbox"
            checked={pillar === "none" ? true : false}
            onChange={() => setPillar("none")}
          />
          <span className={css.checkmark}></span>
        </label>
      </div>
      <Input
        type="text"
        name="title"
        placeholder="Title"
        label="Title"
        value={title}
        required
        className={css.inputText}
        onChange={(e) => {
          setTitle(e.target.value);
          setSlug(slugify(e.target.value));
        }}
        autoComplete="off"
      />
      <Input
        type="text"
        name="slug"
        placeholder="slug"
        label="Slug"
        value={slug}
        required
        className={css.inputText}
        onChange={(e) => setSlug(e.target.value)}
        autoComplete="off"
      />
      {image && <img alt="" src={image} className={css.image} />}
      <div>
        <input
          type="file"
          onChange={(event) => {
            setImageUpload(event.target.files[0]);
          }}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            uploadImage();
          }}
          disabled={progress !== null && progress < 100}
        >
          Upload Image
        </button>
      </div>
      <InputArea
        type="text"
        name="content"
        placeholder="Content"
        label="Content"
        value={content}
        required
        className={css.inputText}
        onChange={(e) => setContent(e.target.value)}
        autoComplete="off"
      />
      <div className={css.footer} onClick={() => handleSubmit()}>
        <button className="saveButton">Save</button>
      </div>
    </div>
  );
};
