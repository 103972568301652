import { useLocation } from "react-router-dom";

import css from "./leftmenu.module.css";
import { ScheduleSidebar } from "./ScheduleSidebar";
import { UserBar } from "./UserBar";
import { HomeBar } from "./HomeSidebar";
import { AdminBar } from "./AdminBar";

export const LeftMenu = (props) => {
  const { pathname } = useLocation();

  let className = css.container;
  if (!props.isMenuVisible) {
    className = css.collapsed;
  }

  function getSidebar() {
    switch (true) {
      case pathname === "/":
        return <HomeBar />;
      case pathname === "/canceled":
        return <HomeBar />;
      case pathname === "/confirmed":
        return <HomeBar />;
      case pathname === "/unconfirmed":
        return <HomeBar />;
      case pathname === "/users":
        return <HomeBar />;
      case pathname === "/schedule":
        return <ScheduleSidebar />;
      case pathname.split("/")[1] === "admin":
        return <AdminBar />;
      case pathname.split("/")[1] === "user":
        return <UserBar id={pathname.split("/")[2]} />;
      default:
        return <HomeBar />;
    }
  }

  return <div className={className}>{getSidebar()}</div>;
};
