import React, { useState, useContext, useEffect } from "react";
import dayjs from "dayjs";

import css from "./Appointments.module.css";
import { UserContext } from "../../Context/UserContext";
import {
  getAppointmentsDocCanceledFirebase,
  updateAppointmentFirebase,
} from "../../Utils/firebaseClinic";
import OutsideAlerter from "../../Utils/OutsideAlerter";

export const Canceled = () => {
  const { userData } = useContext(UserContext);
  const [appoints, setAppoints] = useState([]);
  const [selectedApp, setSelectedApp] = useState(false);
  const [selectedAppStatus, setSelectedAppStatus] = useState(false);
  const [appointmentModal, setAppointmentModal] = useState(false);

  useEffect(() => {
    if (userData) {
      getAppointmentsDocCanceledFirebase(userData.email).then(function (
        result
      ) {
        if (result[0]) {
          let array = result.sort((a, b) =>
            a.dtStart < b.dtStart ? 1 : b.dtStart < a.dtStart ? -1 : 0
          );
          setAppoints(array);
        }
      });
    }
  }, [userData]);

  function handleUpdateAppointment() {
    let updatedApp = {
      id: selectedApp.id,
      userId: selectedApp.userId,
      userName: selectedApp.userName,
      userEmail: selectedApp.userEmail,
      description:
        selectedApp.proName +
        ` | ${dayjs(selectedApp.dtStart).format("DD/MM/YY")} | ${dayjs(
          selectedApp.dtStart
        ).format("HH:mm")} | ${selectedAppStatus}`,
      proName: selectedApp.proName,
      proEmail: selectedApp.proEmail,
      day: selectedApp.day,
      dtStart: selectedApp.dtStart,
      dtEnd: selectedApp.dtEnd,
      status: selectedAppStatus,
      location: selectedApp.location,
      geo: selectedApp.geo,
      categories: selectedApp.categories,
    };
    alert("status da consulta atualizado");
    updateAppointmentFirebase(updatedApp);
    let updatedAppointments = [...appoints];
    updatedAppointments = updatedAppointments.map((app) =>
      app.id === updatedApp.id ? updatedApp : app
    );
    setAppoints([...updatedAppointments]);
  }

  if (appoints) {
    return (
      <>
        <h2>Consultas Canceladas</h2>
        {appoints.map((app) => (
          <div key={app.id} className={css.container}>
            <p>Usuário: {app.userName}</p>
            <p>Email: {app.userEmail}</p>
            <p>Data: {dayjs(app.dtStart).format("DD/MM/YY")}</p>
            <p>Hora: {dayjs(app.dtStart).format("HH:mm")}</p>
            <p>
              Status:{" "}
              <span
                className={app.status === "confirmado" ? css.green : css.red}
              >
                {app.status}
              </span>
            </p>
            <button
              className="defaultButton"
              onClick={() => {
                setSelectedApp(app);
                setSelectedAppStatus(app.status);
                setAppointmentModal(true);
              }}
            >
              Alterar status
            </button>
          </div>
        ))}
        {appointmentModal && (
          <OutsideAlerter setMenuButton={setAppointmentModal}>
            <div className={css.appointmentModal}>
              <h3>Escolher status</h3>
              <p>Usuário: {selectedApp.userName}</p>
              <p>Dia: {dayjs(selectedApp.dtStart).format("DD/MM/YY")}</p>
              <p>Hora: {dayjs(selectedApp.dtStart).format("HH:mm")}</p>
              <p>Status: </p>
              <form>
                <label className={css.confirmation}>
                  Confirmado
                  <input
                    type="checkbox"
                    checked={selectedAppStatus === "confirmado" ? true : false}
                    onChange={() => setSelectedAppStatus("confirmado")}
                  />
                  <span className={css.checkmark}></span>
                </label>
                <label className={css.confirmation}>
                  Não Confirmado
                  <input
                    type="checkbox"
                    checked={
                      selectedAppStatus === "não confirmado" ? true : false
                    }
                    onChange={() => setSelectedAppStatus("não confirmado")}
                  />
                  <span className={css.checkmark}></span>
                </label>
                <label className={css.confirmation}>
                  Cancelado pelo profissional
                  <input
                    type="checkbox"
                    checked={
                      selectedAppStatus === "cancelado pelo profissional"
                        ? true
                        : false
                    }
                    onChange={() =>
                      setSelectedAppStatus("cancelado pelo profissional")
                    }
                  />
                  <span className={css.checkmark}></span>
                </label>
              </form>
              <div>
                <button
                  className="deleteButton"
                  onClick={() => setAppointmentModal(false)}
                >
                  Cancelar
                </button>
                <button
                  className="saveButton"
                  onClick={() => {
                    setAppointmentModal(false);
                    handleUpdateAppointment();
                  }}
                >
                  Salvar
                </button>
              </div>
            </div>
          </OutsideAlerter>
        )}
      </>
    );
  } else {
    return <div>Loading...</div>;
  }
};
