import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./UserProfile.module.css";
import Head from "../../Utils/Head";
import { ClinicContext } from "../../Context/ClinicContext";
import { updateUserFirebase } from "../../Utils/firebaseClinic";

export const UserProfile = () => {
  const { setSelectedPatient, selectedPatient } = useContext(ClinicContext);
  const params = useParams();
  const navigate = useNavigate();
  const traits = [
    "Afetividade Negativa",
    "Desinibição",
    "Distanciamento",
    "Dissociabilidade",
    "Anancastia",
  ];

  function updatePlan() {
    let therapeuticPlan = {};

    if (selectedPatient.therapeuticPlan) {
      therapeuticPlan = selectedPatient.therapeuticPlan;
    } else {
      therapeuticPlan = {
        id: true,
        values: false,
        actionPlan: false,
        personality: false,
        listGoals: false,
        listProblems: false,
      };
    }
    selectedPatient.therapeuticPlan = therapeuticPlan;

    updateUserFirebase(selectedPatient);
  }

  if (!selectedPatient) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <Head title="Profile" description={"Profile - Mairini"} />
        <div className={css.profile}>
          <div className={css.profile1}>
            <i
              className={`material-icons ${css.cardIcon}`}
              onClick={() => navigate("/data")}
            >
              edit
            </i>
            <i className={`material-icons ${css.userIcon}`}>account_circle</i>
            <h4>{selectedPatient.displayName}</h4>
            <p>
              DN: {selectedPatient.birthDate}; Idade: {selectedPatient.age}
            </p>
            <p>Estado social: {selectedPatient.social}</p>
            <p>Educação: {selectedPatient.education}</p>
            <p>Sexo: </p>
          </div>
          <div className={css.profile2}>
            <i
              className={`material-icons ${css.cardIcon}`}
              onClick={() => navigate("/actionplan")}
            >
              edit
            </i>
            <h4>Plano de ação</h4>
            {selectedPatient.actionPlan && (
              <>
                <p>Segunda</p>
                <ul>
                  <li>{selectedPatient.actionPlan[0]}</li>
                </ul>
                <p>Terça</p>
                <ul>
                  <li>{selectedPatient.actionPlan[1]}</li>
                </ul>
                <p>Quarta</p>
                <ul>
                  <li>{selectedPatient.actionPlan[2]}</li>
                </ul>
                <p>Quinta</p>
                <ul>
                  <li>{selectedPatient.actionPlan[3]}</li>
                </ul>
                <p>Sexta</p>
                <ul>
                  <li>{selectedPatient.actionPlan[4]}</li>
                </ul>
                <p>Sábado</p>
                <ul>
                  <li>{selectedPatient.actionPlan[5]}</li>
                </ul>
                <p>Domingo</p>
                <ul>
                  <li>{selectedPatient.actionPlan[6]}</li>
                </ul>
              </>
            )}
          </div>
          <div className={css.profile3}>
            <i
              className={`material-icons ${css.cardIcon}`}
              onClick={() => navigate("/personality")}
            >
              edit_note
            </i>
            <h4>Personalidade</h4>
            {selectedPatient.personalityTraits &&
              selectedPatient.personalityTraits.map((p, i) => (
                <>
                  <div key={i} className={css.containerBar}>
                    <span className={css.textBar}>{traits[i]}</span>
                    <div
                      className={css.progressBar}
                      style={{
                        width:
                          Math.round(
                            (p * 100) /
                              (selectedPatient.personalityTraits[0] +
                                selectedPatient.personalityTraits[1] +
                                selectedPatient.personalityTraits[2] +
                                selectedPatient.personalityTraits[3] +
                                selectedPatient.personalityTraits[4])
                          ) + "%",
                      }}
                    >
                      <span>
                        {Math.round(
                          (p * 100) /
                            (selectedPatient.personalityTraits[0] +
                              selectedPatient.personalityTraits[1] +
                              selectedPatient.personalityTraits[2] +
                              selectedPatient.personalityTraits[3] +
                              selectedPatient.personalityTraits[4])
                        )}
                      </span>
                    </div>
                  </div>
                </>
              ))}
          </div>
          <div className={css.profile4}>
            <i
              className={`material-icons ${css.cardIcon}`}
              onClick={() => navigate("/listproblems")}
            >
              edit
            </i>
            <h4>Lista de Problemas</h4>
            {selectedPatient.listProblems &&
              selectedPatient.listProblems.map((p, i) => (
                <ul key={i}>
                  <li>{p}</li>
                </ul>
              ))}
          </div>
          <div className={css.profile5}>
            <i
              className={`material-icons ${css.cardIcon}`}
              onClick={() => navigate("/values")}
            >
              edit_note
            </i>
            <h4>Valores</h4>
            {/* <div className={css.wheel}>
              <div className={css.wheel1}></div>
              <div className={css.wheel2}></div>
              <div className={css.wheel3}></div>
              <div className={css.wheel4}></div>
            </div> */}

            {selectedPatient.listValues && (
              <>
                <p>Relações familiares: {selectedPatient.listValues[0]}</p>
                <p>Companheiro afetivo: {selectedPatient.listValues[1]}</p>
                <p>Ser pai ou mãe: {selectedPatient.listValues[2]}</p>
                <p>Amizades: {selectedPatient.listValues[3]}</p>
                <p>Trabalho: {selectedPatient.listValues[4]}</p>
                <p>Educação: {selectedPatient.listValues[5]}</p>
                <p>Lazer e Bem-estar: {selectedPatient.listValues[6]}</p>
                <p>Espiritualidade: {selectedPatient.listValues[7]}</p>
                <p>Cidadania: {selectedPatient.listValues[8]}</p>
                <p>Autocuidado: {selectedPatient.listValues[9]}</p>
              </>
            )}
          </div>
          <div className={css.profile6}>
            <i
              className={`material-icons ${css.cardIcon}`}
              onClick={() => navigate("/listgoals")}
            >
              edit
            </i>
            <h4>Lista de Metas</h4>
            {selectedPatient.listGoals &&
              selectedPatient.listGoals.map((p, i) => (
                <ul key={i}>
                  <li>{p}</li>
                </ul>
              ))}
          </div>
        </div>
      </>
    );
  }
};
