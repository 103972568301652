import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import css from "./header.module.css";
import { ReactComponent as Logo } from "../../assets/mairini-logo.svg";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { UserContext } from "../../Context/UserContext";

export const Header = () => {
  const { user, userData, userLogout } = React.useContext(UserContext);
  const [menuButton, setMenuButton] = React.useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (!user && !userData) {
    return (
      <div className={css.topbar}>
        <div className={css.topbarWrapper}>
          <div className={css.topLeft}>
            <div className={css.logo}>
              <Link className={css.logo} to="/" aria-label="Mairini - Home">
                <Logo />
                <h4>Pro.Mairini</h4>
              </Link>
            </div>
          </div>
          <div className={css.topRight}>
            <div className={css.avatar}>
              <div className={css.menuContainer}>
                <button
                  className="defaultButton"
                  onClick={() => navigate("/login/")}
                >
                  Entrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (userData) {
    return (
      <div className={css.topbar}>
        <div className={css.topbarWrapper}>
          <div className={css.topLeft}>
            <div>
              <Link className={css.logo} to="/" aria-label="Mairini - Home">
                <Logo />
                <h4>Pro.Mairini</h4>
              </Link>
            </div>
            <div className={css.tollbar}>
              <Link
                className={
                  pathname === "/" ? css.tollbarItensActive : css.tollbarItens
                }
                to="/"
              >
                Início
              </Link>
              <Link
                className={
                  pathname === "/schedule"
                    ? css.tollbarItensActive
                    : css.tollbarItens
                }
                to="/schedule"
              >
                Agenda
              </Link>
              {userData.staff && (
                <Link
                  className={
                    pathname.split("/")[1] === "admin"
                      ? css.tollbarItensActive
                      : css.tollbarItens
                  }
                  to="/admin"
                >
                  Admin
                </Link>
              )}
            </div>
          </div>

          <div className={css.topRight}>
            {/* <div className={css.topbarIconContainer}>
              <i className="material-icons materialIcon">notifications_none</i>
              <span className={css.topIconBadge}>1</span>
            </div> */}

            <div className={css.avatar}>
              <div className={css.menuContainer}>
                <OutsideAlerter setMenuButton={setMenuButton}>
                  <button
                    className={css.userButton}
                    onClick={() => setMenuButton(!menuButton)}
                  />
                  {menuButton && (
                    <nav className={css.navUserButton}>
                      <ul>
                        <Link to="/">
                          <li>
                            <button onClick={() => setMenuButton(!menuButton)}>
                              Settings
                            </button>
                          </li>
                        </Link>
                        <li>
                          <button onClick={userLogout}>Logout</button>
                        </li>
                      </ul>
                    </nav>
                  )}
                </OutsideAlerter>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
