import React from "react";
import { Link, useLocation } from "react-router-dom";

import { UserContext } from "../../Context/UserContext";
import SmallCalendar from "../Schedule/SmallCalendar";
import css from "./Sidebar.module.css";

export const HomeBar = () => {
  const { userData } = React.useContext(UserContext);
  const { pathname } = useLocation();

  if (userData) {
    return (
      <div className={css.sidebar}>
        <div className={css.sidebarWrapper}>
          <div className={css.sidebarMenu}>
            <h5>{userData.displayName}</h5>
            <ul className={css.sidebarList}>
              {pathname === "/" ? (
                <Link to="/" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>home</i>
                    Início
                  </li>
                </Link>
              ) : (
                <Link to="/" className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>home</i>
                    Início
                  </li>
                </Link>
              )}

              {pathname === "/unconfirmed" ? (
                <Link to="/unconfirmed" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      medical_services
                    </i>
                    Solicitações
                  </li>
                </Link>
              ) : (
                <Link to="/unconfirmed" className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      medical_services
                    </i>
                    Solicitações
                  </li>
                </Link>
              )}

              {pathname === "/confirmed" ? (
                <Link to="/confirmed" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      work_history
                    </i>
                    Confirmadas
                  </li>
                </Link>
              ) : (
                <Link to="/confirmed" className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      work_history
                    </i>
                    Confirmadas
                  </li>
                </Link>
              )}

              {pathname === "/canceled" ? (
                <Link to="/canceled" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      work_off
                    </i>
                    Canceladas
                  </li>
                </Link>
              ) : (
                <Link to="/canceled" className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      work_off
                    </i>
                    Canceladas
                  </li>
                </Link>
              )}

              {pathname === "/users" ? (
                <Link to="/users" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      account_box
                    </i>
                    Usuários
                  </li>
                </Link>
              ) : (
                <Link to="/users" className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      account_box
                    </i>
                    Usuários
                  </li>
                </Link>
              )}

              {pathname === "/editschedule" ? (
                <Link to="/editschedule" className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      dashboard
                    </i>
                    Escala
                  </li>
                </Link>
              ) : (
                <Link to="/editschedule" className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      dashboard
                    </i>
                    Escala
                  </li>
                </Link>
              )}
            </ul>
          </div>
          <div
            className={`copyright ${
              userData.staff ? css.sidebarMenu : css.copyright
            } `}
          >
            mairini.com © 2023
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
