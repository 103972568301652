import React, { useContext } from "react";

import css from "./AdminPage.module.css";
import Head from "../../Utils/Head";
import { ClinicContext } from "../../Context/ClinicContext";

export const AdminPage = () => {
  const { users, actTechsList, practiceList } = useContext(ClinicContext);

  return (
    <div>
      <Head title="Admin" description={"Admin - Mairini"} />

      <div className={css.card}>
        <h5>Usuários</h5>
        <p>Usuários: {users.length}</p>
      </div>
      <div className={css.card}>
        <h5>Posts</h5>
        <p>ActTechs: {actTechsList.length}</p>
        <p>Praticas: {practiceList.length}</p>
      </div>
    </div>
  );
};
