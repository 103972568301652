import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ClinicContext } from "../../Context/ClinicContext";

import css from "./Sidebar.module.css";
import { UserContext } from "../../Context/UserContext";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import {
  delWaitingFirebase,
  getUserByIdFirebase,
  updateAppointmentFirebase,
} from "../../Utils/firebaseClinic";

export const UserBar = ({ id }) => {
  const { userData } = useContext(UserContext);
  const params = useParams();
  const {
    selectedPatient,
    setSelectedPatient,
    waitingList,
    selectedAppointment,
    setSelectedAppointment,
    appointmentsDay,
    setAppointmentsDay,
    setWaitingList,
  } = useContext(ClinicContext);
  const { pathname } = useLocation();
  const [showEndAttendance, setShowEndAttendance] = useState(false);
  const [attendance, setAttendance] = useState(
    waitingList.map((a) => a.userId).indexOf(selectedPatient.id) >= 0
      ? true
      : false
  );

  useEffect(() => {
    getUserByIdFirebase(params.id).then(function (result) {
      setSelectedPatient(result[0]);
    });
  }, []);

  function handleFinish() {
    const updatedAppointment = {
      id: selectedAppointment.id,
      userId: selectedAppointment.userId,
      userName: selectedAppointment.userName,
      userEmail: selectedAppointment.userEmail,
      description:
        selectedAppointment.userName +
        ` | ${selectedAppointment.start} - ${selectedAppointment.end} | ` +
        "atendido",
      proName: selectedAppointment.proName,
      proEmail: selectedAppointment.proEmail,
      day: selectedAppointment.day,
      start: selectedAppointment.start,
      end: selectedAppointment.end,
      status: "atendido",
      location: "",
      geo: "",
      categories: "",
    };
    let updatedAppointmentsDay = appointmentsDay.map((app) =>
      app.id === updatedAppointment.id ? updatedAppointment : app
    );
    setAppointmentsDay(updatedAppointmentsDay);
    updateAppointmentFirebase(updatedAppointment);
    let list = waitingList.filter(
      (w) => w.userId !== selectedAppointment.userId
    );
    setWaitingList(list);
    delWaitingFirebase(selectedAppointment);
    setSelectedAppointment(false);
    setAttendance(false);
    setShowEndAttendance(false);
  }

  if (selectedPatient.displayName) {
    return (
      <div className={css.sidebar}>
        <div className={css.sidebarWrapper}>
          <div className={css.sidebarMenu}>
            <h5>{selectedPatient.displayName}, XX anos</h5>
            <ul className={css.sidebarList}>
              {attendance && (
                <li
                  className={`${css.sidebarListItem} ${css.status}`}
                  onClick={() => setShowEndAttendance(true)}
                >
                  em atendimento
                </li>
              )}

              {pathname.split("/")[3] === "today" || pathname === "/start" ? (
                <Link
                  to={userData ? `/user/${id}/today` : "/start"}
                  className={css.link}
                >
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i
                      className={`material-icons ${css.sidebarIcon} ${css.active}`}
                    >
                      today
                    </i>
                    Hoje
                  </li>
                </Link>
              ) : (
                <Link
                  to={userData ? `/user/${id}/today` : "/start"}
                  className={css.link}
                >
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>today</i>
                    Hoje
                  </li>
                </Link>
              )}

              {pathname.split("/")[3] === "schedule" ? (
                <Link to={`/user/${id}/schedule`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      calendar_view_month
                    </i>
                    Semana
                  </li>
                </Link>
              ) : (
                <Link to={`/user/${id}/schedule`} className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      calendar_view_month
                    </i>
                    Semana
                  </li>
                </Link>
              )}

              {pathname.split("/")[3] === "profile" ? (
                <Link to={`/user/${id}/profile`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      person
                    </i>
                    Perfil
                  </li>
                </Link>
              ) : (
                <Link to={`/user/${id}/profile`} className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      person
                    </i>
                    Perfil
                  </li>
                </Link>
              )}

              <hr />

              {pathname.split("/")[3] === "record" ? (
                <Link to={`/user/${id}/record`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      article
                    </i>
                    Anamnese
                  </li>
                </Link>
              ) : (
                <Link to={`/user/${id}/record`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.disabled}`}>
                    <i
                      className={`material-icons ${css.sidebarIcon} ${css.disabled}`}
                    >
                      article
                    </i>
                    Anamnese
                  </li>
                </Link>
              )}

              {pathname.split("/")[3] === "act" ? (
                <Link to={`/user/${id}/act`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      accessibility_new
                    </i>
                    Acts
                  </li>
                </Link>
              ) : (
                <Link to={`/user/${id}/act`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.disabled}`}>
                    <i
                      className={`material-icons ${css.sidebarIcon} ${css.disabled}`}
                    >
                      accessibility_new
                    </i>
                    Acts
                  </li>
                </Link>
              )}

              {pathname.split("/")[3] === "scales" ? (
                <Link to={`/user/${id}/scales`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      show_chart
                    </i>
                    Escalas
                  </li>
                </Link>
              ) : (
                <Link to={`/user/${id}/scales`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.disabled}`}>
                    <i
                      className={`material-icons ${css.sidebarIcon} ${css.disabled}`}
                    >
                      show_chart
                    </i>
                    Escalas
                  </li>
                </Link>
              )}

              {/* {pathname.split("/")[3] === "prescription" ? (
                <Link to={`/user/${id}/prescription`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      local_pharmacy
                    </i>
                    Receituário
                  </li>
                </Link>
              ) : (
                <Link to={`/user/${id}/prescription`} className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      local_pharmacy
                    </i>
                    Receituário
                  </li>
                </Link>
              )}

              {pathname.split("/")[3] === "certificate" ? (
                <Link to={`/user/${id}/certificate`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      spellcheck
                    </i>
                    Atestado
                  </li>
                </Link>
              ) : (
                <Link to={`/user/${id}/certificate`} className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      spellcheck
                    </i>
                    Atestado
                  </li>
                </Link>
              )} */}
            </ul>
          </div>

          {showEndAttendance && (
            <OutsideAlerter setMenuButton={setShowEndAttendance}>
              <div className={css.modal}>
                <header className={css.modalHeader}>
                  <span
                    onClick={() => setShowEndAttendance(false)}
                    className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                  >
                    close
                  </span>
                </header>
                <div className={css.modalBody}>
                  Finalizar atendimento
                  <button className="saveButton" onClick={() => handleFinish()}>
                    Terminar Atendimento
                  </button>
                </div>
              </div>
            </OutsideAlerter>
          )}

          <div
            className={`copyright ${
              userData.staff ? css.sidebarMenu : css.copyright
            } `}
          >
            mairini.com © 2024
          </div>
        </div>
      </div>
    );
  } else if (userData) {
    return (
      <div className={css.sidebar}>
        <div className={css.sidebarWrapper}></div>
        <div className={css.sidebarMenu}>
          <h5>..., XX anos</h5>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
