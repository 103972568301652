import React, { useContext, useState, useEffect } from "react";
import dayjs from "dayjs";

import { ClinicContext } from "../../Context/ClinicContext";
import css from "./UserToday.module.css";
import Head from "../../Utils/Head";
import { updateUserFirebase } from "../../Utils/firebaseClinic";

export const UserToday = () => {
  const { selectedPatient, practiceList, actTechsList } =
    useContext(ClinicContext);
  const [mindEditModal, setMindEditModal] = useState(false);
  const [mindModal, setMindModal] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const [pillar, setPillar] = useState("mind");
  const [tasks, setTasks] = useState("");
  const [h05h07, seth05h07] = useState(new Array(7).fill(""));
  const [h07h09, seth07h09] = useState(new Array(7).fill(""));
  const [h09h11, seth09h11] = useState(new Array(7).fill(""));
  const [h11h13, seth11h13] = useState(new Array(7).fill(""));
  const [h13h15, seth13h15] = useState(new Array(7).fill(""));
  const [h15h17, seth15h17] = useState(new Array(7).fill(""));
  const [h17h19, seth17h19] = useState(new Array(7).fill(""));
  const [h19h21, seth19h21] = useState(new Array(7).fill(""));
  const [h21h23, seth21h23] = useState(new Array(7).fill(""));

  useEffect(() => {
    if (selectedPatient.schedule) {
      seth05h07(selectedPatient.schedule.h05h07);
      seth07h09(selectedPatient.schedule.h07h09);
      seth09h11(selectedPatient.schedule.h09h11);
      seth11h13(selectedPatient.schedule.h11h13);
      seth13h15(selectedPatient.schedule.h13h15);
      seth15h17(selectedPatient.schedule.h15h17);
      seth17h19(selectedPatient.schedule.h17h19);
      seth19h21(selectedPatient.schedule.h19h21);
      seth21h23(selectedPatient.schedule.h21h23);
    }
  }, [selectedPatient]);

  function updateActTech(c) {
    selectedPatient.actTech = c;
    updateUserFirebase(selectedPatient);
  }

  if (selectedPatient) {
    return (
      <div className={css.container}>
        <Head title="Today" description={"User - Mairini"} />
        <section>
          <div className={css.card}>
            <h4>Agenda {dayjs().format("DD.MM.YY")}</h4>
            {selectedPatient.schedule && (
              <div className={css.scheduleGrid}>
                <div>
                  <h5>MANHÃ</h5>
                  <p
                    onClick={() => {
                      h05h07[dayjs().day()].title
                        ? setTaskModal(h05h07[dayjs().day()].title)
                        : setTaskModal("Início da manhã");
                      h05h07[dayjs().day()].pillar
                        ? setPillar(h05h07[dayjs().day()].pillar)
                        : setPillar("");
                      h05h07[dayjs().day()].tasks
                        ? setTasks(h05h07[dayjs().day()].tasks)
                        : setTasks([]);
                    }}
                  >
                    {h05h07[dayjs().day()].title}
                  </p>
                  <p
                    onClick={() => {
                      h07h09[dayjs().day()].title
                        ? setTaskModal(h07h09[dayjs().day()].title)
                        : setTaskModal("Meio da manhã");
                      h07h09[dayjs().day()].pillar
                        ? setPillar(h07h09[dayjs().day()].pillar)
                        : setPillar("");
                      h07h09[dayjs().day()].tasks
                        ? setTasks(h07h09[dayjs().day()].tasks)
                        : setTasks([]);
                    }}
                  >
                    {h07h09[dayjs().day()].title}
                  </p>
                  <p
                    onClick={() => {
                      h09h11[dayjs().day()].title
                        ? setTaskModal(h09h11[dayjs().day()].title)
                        : setTaskModal("Fim da manhã");
                      h09h11[dayjs().day()].pillar
                        ? setPillar(h09h11[dayjs().day()].pillar)
                        : setPillar("");
                      h09h11[dayjs().day()].tasks
                        ? setTasks(h09h11[dayjs().day()].tasks)
                        : setTasks([]);
                    }}
                  >
                    {h09h11[dayjs().day()].title}
                  </p>
                </div>
                <div>
                  <h5>TARDE</h5>
                  <p
                    onClick={() => {
                      h11h13[dayjs().day()].title
                        ? setTaskModal(h11h13[dayjs().day()].title)
                        : setTaskModal("Início da tarde");
                      h11h13[dayjs().day()].pillar
                        ? setPillar(h11h13[dayjs().day()].pillar)
                        : setPillar("");
                      h11h13[dayjs().day()].tasks
                        ? setTasks(h11h13[dayjs().day()].tasks)
                        : setTasks([]);
                    }}
                  >
                    {h11h13[dayjs().day()].title}
                  </p>
                  <p
                    onClick={() => {
                      h13h15[dayjs().day()].title
                        ? setTaskModal(h13h15[dayjs().day()].title)
                        : setTaskModal("Meio da tarde");
                      h13h15[dayjs().day()].pillar
                        ? setPillar(h13h15[dayjs().day()].pillar)
                        : setPillar("");
                      h13h15[dayjs().day()].tasks
                        ? setTasks(h13h15[dayjs().day()].tasks)
                        : setTasks([]);
                    }}
                  >
                    {h13h15[dayjs().day()].title}
                  </p>
                  <p
                    onClick={() => {
                      h15h17[dayjs().day()].title
                        ? setTaskModal(h15h17[dayjs().day()].title)
                        : setTaskModal("Fim da tarde");
                      h15h17[dayjs().day()].pillar
                        ? setPillar(h15h17[dayjs().day()].pillar)
                        : setPillar("");
                      h15h17[dayjs().day()].tasks
                        ? setTasks(h15h17[dayjs().day()].tasks)
                        : setTasks([]);
                    }}
                  >
                    {h15h17[dayjs().day()].title}
                  </p>
                </div>
                <div>
                  <h5>NOITE</h5>
                  <p
                    onClick={() => {
                      h17h19[dayjs().day()].title
                        ? setTaskModal(h17h19[dayjs().day()].title)
                        : setTaskModal("Início da noite");
                      h17h19[dayjs().day()].pillar
                        ? setPillar(h17h19[dayjs().day()].pillar)
                        : setPillar("");
                      h17h19[dayjs().day()].tasks
                        ? setTasks(h17h19[dayjs().day()].tasks)
                        : setTasks([]);
                    }}
                  >
                    {h17h19[dayjs().day()].title}
                  </p>
                  <p
                    onClick={() => {
                      h19h21[dayjs().day()].title
                        ? setTaskModal(h19h21[dayjs().day()].title)
                        : setTaskModal("Meio da noite");
                      h19h21[dayjs().day()].pillar
                        ? setPillar(h19h21[dayjs().day()].pillar)
                        : setPillar("");
                      h19h21[dayjs().day()].tasks
                        ? setTasks(h19h21[dayjs().day()].tasks)
                        : setTasks([]);
                    }}
                  >
                    {h19h21[dayjs().day()].title}
                  </p>
                  <p
                    onClick={() => {
                      h21h23[dayjs().day()].title
                        ? setTaskModal(h21h23[dayjs().day()].title)
                        : setTaskModal("Fim da noite");
                      h21h23[dayjs().day()].pillar
                        ? setPillar(h21h23[dayjs().day()].pillar)
                        : setPillar("");
                      h21h23[dayjs().day()].tasks
                        ? setTasks(h21h23[dayjs().day()].tasks)
                        : setTasks([]);
                    }}
                  >
                    {h21h23[dayjs().day()].title}
                  </p>
                </div>
              </div>
            )}
          </div>

          {selectedPatient.actTech ? (
            <div className={css.actTech}>
              <div className={css.actTechHead}>
                <h4>Exercício recomendado</h4>
                <i
                  className={`material-icons materialIcon`}
                  onClick={() => setMindEditModal(true)}
                >
                  edit
                </i>
              </div>
              <div className={css.mindGrid} onClick={() => setMindModal(true)}>
                <div>
                  <p>{selectedPatient.actTech.title}</p>
                </div>
                <div></div>
              </div>
            </div>
          ) : (
            <div className={css.card}>
              <h4>ActTech</h4>
              <div>
                <p>Nenhum ActTech selecionado para o usuário</p>
                <button
                  className="defaultButton"
                  onClick={() => setMindEditModal(true)}
                >
                  Add a ActTech
                </button>
              </div>
            </div>
          )}
        </section>

        {taskModal && (
          <div className={css.modalContainer}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setTaskModal(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody}>
                <h3>{taskModal + ` - ${dayjs().format("DD.MM.YY")}`}</h3>
                <div>
                  {pillar === "mind" && <p>Pillar: Mind</p>}
                  {pillar === "move" && <p>Pillar: Move</p>}
                  {pillar === "nutri" && <p>Pillar: Nutri</p>}
                  {pillar === "none" && <p>Pillar: Outra</p>}
                </div>
                {tasks[0] && <p>Pratica 1: {tasks[0]}</p>}
                {tasks[1] && <p>Pratica 2: {tasks[1]}</p>}
                {tasks[2] && <p>Pratica 3: {tasks[2]}</p>}
                {tasks[3] && <p>Pratica 4: {tasks[3]}</p>}
                {tasks[4] && <p>Pratica 5: {tasks[4]}</p>}
                {tasks[5] && <p>Pratica 6: {tasks[5]}</p>}
                {tasks[6] && <p>Pratica 7: {tasks[6]}</p>}
                <footer className={css.modalFooter}>
                  <button
                    className="deleteButton"
                    onClick={(e) => {
                      setTaskModal(false);
                    }}
                  >
                    Fechar
                  </button>
                </footer>
              </div>
            </div>
          </div>
        )}
        {mindEditModal && (
          <div className={css.modalContainer}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setMindEditModal(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody2}>
                <h3>ActTechs</h3>
                <div>
                  {actTechsList.map((c) => (
                    <p
                      key={c.id}
                      onClick={() => {
                        updateActTech(c);
                        setMindEditModal(false);
                      }}
                    >
                      {c.title}
                    </p>
                  ))}
                </div>
                <footer className={css.modalFooter}>
                  <button
                    className="deleteButton"
                    onClick={(e) => {
                      setMindEditModal(false);
                    }}
                  >
                    Fechar
                  </button>
                </footer>
              </div>
            </div>
          </div>
        )}

        {mindModal && (
          <div className={css.modalContainer}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setMindModal(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody}>
                <h3>{selectedPatient.actTech.title}</h3>
                <div>{selectedPatient.actTech.content}</div>
                <footer className={css.modalFooter}>
                  <button
                    className="deleteButton"
                    onClick={(e) => {
                      setMindModal(false);
                    }}
                  >
                    Fechar
                  </button>
                </footer>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
