import React, { useState } from "react";

import css from "./Home.module.css";
import { getUserFirebase } from "../Utils/firebaseClinic";

export const Users = () => {
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");
  const [selectedUser, setSelectedUser] = useState(false);

  function searchUser(e) {
    e.preventDefault();
    getUserFirebase(searchQuery).then(function (result) {
      if (result[0]) {
        setSelectedUser(result[0]);
      } else {
        alert("usuário não encontrado");
      }
    });
  }

  return (
    <div className={css.content}>
      <h2>Usuários Adicionados</h2>
      {/* <form>
        <input
          value={searchQuery}
          placeholder="Pesquisar por email..."
          onInput={(e) => setSearchQuery(e.target.value)}
          type="text"
          id="header-search"
          name="s"
          autoComplete="off"
        />
        <button className="defaultButton" onClick={(e) => searchUser(e)}>
          Pesquisar
        </button>
      </form>
      {selectedUser.displayName && (
        <section>
          <h4>Dados do usuário</h4>
          <p>Nome: {selectedUser.displayName}</p>
          <p>Email: {selectedUser.email}</p>
        </section>
      )} */}
    </div>
  );
};
