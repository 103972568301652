import React from "react";
import { useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updateProfile,
  sendEmailVerification,
  signInWithPopup,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db, auth, googleProvider } from "../firebaseConfig";

export const UserContext = React.createContext();

export const UserStorage = ({ children }) => {
  const [userData, setUserData] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [error, setError] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setLoading(true);
      if (currentUser) {
        setUser(currentUser);
        getDocument("users", currentUser.uid).then(function (result) {
          setUserData(result);
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const userRegister = async (displayName, email, password) => {
    try {
      setError(null);
      setLoading(true);
      const res = await createUserWithEmailAndPassword(auth, email, password);
      sendEmailVerification(auth.currentUser).then(() => {
        console.log("Email verification sent!");
        // ...
      });
      const u = res.user;
      await updateProfile(auth.currentUser, {
        displayName,
      });
      await setDoc(doc(db, "users", u.uid), {
        id: u.uid,
        displayName,
        email,
        category: "patient",
        clinic: [],
        research: false,
        member: false,
        staff: false,
      });
      setUser(u);
      setUserData(getDocument("users", u.uid));
      navigate("/");
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const userLogin = async (email, password) => {
    try {
      setError(null);
      setLoading(true);
      const u = await signInWithEmailAndPassword(auth, email, password);
      setUser(u.user);
      setUserData(getDocument("users", u.user.uid));
      navigate("/");
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // const setNewGoogleUser = async (auth, googleUser) => {
  //   await setDoc(doc(db, "users", auth.currentUser.uid), {
  //     id: auth.currentUser.uid,
  //     displayName: googleUser.user.displayName,
  //     email: googleUser.user.email,
  //     category: "patient",
  //     member: false,
  //     staff: false,
  //     profilePic: googleUser.user.photoURL ? googleUser.user.photoURL : false,
  //   });
  //   let newUser = {
  //     id: auth.currentUser.uid,
  //     displayName: googleUser.user.displayName,
  //     email: googleUser.user.email,
  //     category: "patient",
  //     member: false,
  //     staff: false,
  //     profilePic: googleUser.user.photoURL ? googleUser.user.photoURL : false,
  //   };
  //   setUser(googleUser.user);
  //   setUserData(newUser);
  // };

  const signInWithGoogle = async () => {
    try {
      setError(null);
      setLoading(true);
      const googleUser = await signInWithPopup(auth, googleProvider);
      console.log(googleUser);
      await updateProfile(auth.currentUser, {
        displayName: googleUser.user.displayName,
      });
      let userLogging = "";
      await getDocument("users", auth.currentUser.uid).then(function (result) {
        userLogging = result;
      });
      await setDoc(doc(db, "users", auth.currentUser.uid), {
        id: auth.currentUser.uid,
        displayName: googleUser.user.displayName,
        email: googleUser.user.email,
        category: userLogging ? userLogging.category : "patient",
        clinic: userLogging ? userLogging.clinic : [],
        research: userLogging ? userLogging.research : false,
        member: userLogging ? userLogging.member : false,
        staff: userLogging ? userLogging.staff : false,
        profilePic: googleUser.user.photoURL ? googleUser.user.photoURL : false,
      });
      setUser(googleUser.user);
      setUserData(getDocument("users", auth.currentUser.uid));
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getDocument = async (collection, uid) => {
    const docRef = doc(db, collection, uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const userLogout = () => {
    signOut(auth);
    setUserData(null);
    setUser(null);
    navigate("/");
  };

  const passwordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      navigate("/");
      alert("Password reset link sent!");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  return (
    <UserContext.Provider
      value={{
        userRegister,
        userLogin,
        user,
        userData,
        setUserData,
        userLogout,
        getDocument,
        loading,
        error,
        passwordReset,
        signInWithGoogle,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
