import React, { useState, useContext, useEffect } from "react";

import css from "./EditSchedule.module.css";
import { ClinicContext } from "../../Context/ClinicContext";
import { createProFirebase } from "../../Utils/firebaseClinic";

export const EditSchedule = () => {
  const { proSelected } = useContext(ClinicContext);
  const [weekDay, setWeekDay] = useState(0);
  const scheduleArr = new Array(7);
  for (let i = 0; i < scheduleArr.length; i++) {
    scheduleArr[i] = new Array(48).fill(false);
  }
  const [scheduleCheck, setScheduleCheck] = useState(scheduleArr);
  const weekArray = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ];
  const dayArray = [];
  for (var i = 1; i <= 24; i++) {
    dayArray.push(i + ":00");
    dayArray.push(i + ":30");
  }

  useEffect(() => {
    const proSchedule = new Array(7);
    for (let i = 0; i < proSchedule.length; i++) {
      proSchedule[i] = new Array(48).fill(false);
    }
    if (proSelected.schedSun) {
      proSchedule[0] = proSelected.schedSun;
      proSchedule[1] = proSelected.schedMon;
      proSchedule[2] = proSelected.schedTue;
      proSchedule[3] = proSelected.schedWed;
      proSchedule[4] = proSelected.schedThu;
      proSchedule[5] = proSelected.schedFri;
      proSchedule[6] = proSelected.schedSat;
      setScheduleCheck(proSchedule);
    }
  }, [proSelected]);

  function handleCheck(row, col) {
    let array = [...scheduleCheck];
    if (array[row][col]) {
      array[row][col] = false;
      setScheduleCheck(array);
    } else {
      array[row][col] = true;
      setScheduleCheck(array);
    }
  }

  function updateSchedule() {
    const updatedPro = {
      clinic: proSelected.clinic,
      displayName: proSelected.displayName,
      email: proSelected.email,
      id: proSelected.id,
      pro: proSelected.pro,
      tele: proSelected.tele,
      schedSun: scheduleCheck[0],
      schedMon: scheduleCheck[1],
      schedTue: scheduleCheck[2],
      schedWed: scheduleCheck[3],
      schedThu: scheduleCheck[4],
      schedFri: scheduleCheck[5],
      schedSat: scheduleCheck[6],
    };
    createProFirebase(updatedPro);
    alert(proSelected.displayName + " atualizado");
  }

  if (proSelected) {
    return (
      <>
        <div className={css.care}>
          <h4>Editar Escala</h4>
          <button className="saveButton" onClick={() => updateSchedule()}>
            Atualizar
          </button>
          <p>Atendimento em clínica: {proSelected.clinic ? "sim" : "não"}</p>
          <p>Teleatendimento: {proSelected.tele ? "sim" : "não"}</p>
        </div>
        <div className={css.week}>
          {weekArray.map((day, i) => (
            <button
              key={i}
              className={
                i === weekDay ? "defaultActiveButton" : "defaultButton"
              }
              onClick={() => setWeekDay(i)}
            >
              {day}
            </button>
          ))}
        </div>
        <div>
          {dayArray.map((time, col) => (
            <label className={css.confirmation} key={col}>
              <p>{time}</p>
              <input
                type="checkbox"
                checked={scheduleCheck[weekDay][col]}
                onChange={() => {
                  handleCheck(weekDay, col);
                }}
              />
              <span className={css.checkmark}></span>
            </label>
          ))}
        </div>
      </>
    );
  } else {
    return <div>Loading...</div>;
  }
};
