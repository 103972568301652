import React, { useState, useContext } from "react";

import { ClinicContext } from "../../Context/ClinicContext";
import Head from "../../Utils/Head";
import DayView from "./DayView";
// import MonthView from "./MonthView";

export const Schedule = () => {
  const { smallCalendarMonth, view } = useContext(ClinicContext);
  // const [currentMonth, setCurrentMonth] = useState(getMonth());

  // React.useEffect(() => {
  //   setCurrentMonth(getMonth(smallCalendarMonth));
  // }, [smallCalendarMonth]);

  return (
    <>
      <Head title="Clinic" description="Clinic - Mairimed" />
      {/* {view === "month" && <MonthView month={currentMonth} />} */}
      {view === "day" && <DayView />}
    </>
  );
};
