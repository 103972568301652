import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ClinicContext } from "../../Context/ClinicContext";

import css from "./Sidebar.module.css";
import { UserContext } from "../../Context/UserContext";

export const AdminBar = ({ id }) => {
  const { userData } = useContext(UserContext);
  const { pathname } = useLocation();

  if (userData) {
    return (
      <div className={css.sidebar}>
        <div className={css.sidebarWrapper}>
          <div className={css.sidebarMenu}>
            <h5>{userData.displayName}</h5>
            <ul className={css.sidebarList}>
              {pathname === "/admin" ? (
                <Link to={`/admin`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      star_rate
                    </i>
                    Início
                  </li>
                </Link>
              ) : (
                <Link to={`/admin`} className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      star_rate
                    </i>
                    Início
                  </li>
                </Link>
              )}

              {pathname === "/admin/acttechs" ? (
                <Link to={`/admin/acttechs`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      star_rate
                    </i>
                    ActTechs
                  </li>
                </Link>
              ) : (
                <Link to={`/admin/acttechs`} className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      star_rate
                    </i>
                    ActTechs
                  </li>
                </Link>
              )}

              {pathname === "/admin/practices" ? (
                <Link to={`/admin/practices`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      star_rate
                    </i>
                    Práticas
                  </li>
                </Link>
              ) : (
                <Link to={`/admin/practices`} className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      star_rate
                    </i>
                    Práticas
                  </li>
                </Link>
              )}

              {pathname === "/admin/newacttech" ? (
                <Link to={`/admin/newacttech`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      edit_note
                    </i>
                    Nova Act Tech
                  </li>
                </Link>
              ) : (
                <Link to={`/admin/newacttech`} className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      edit_note
                    </i>
                    Nova Act Tech
                  </li>
                </Link>
              )}

              {pathname === "/admin/newpractice" ? (
                <Link to={`/admin/newpractice`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      accessibility
                    </i>
                    Nova Prática
                  </li>
                </Link>
              ) : (
                <Link to={`/admin/newpractice`} className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      accessibility
                    </i>
                    Nova Prática
                  </li>
                </Link>
              )}

              {pathname === "/admin/users" ? (
                <Link to={`/admin/users`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      person
                    </i>
                    Usuários
                  </li>
                </Link>
              ) : (
                <Link to={`admin/users`} className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      person
                    </i>
                    Usuários
                  </li>
                </Link>
              )}
            </ul>
          </div>

          <div
            className={`copyright ${
              userData.staff ? css.sidebarMenu : css.copyright
            } `}
          >
            mairini.com © 2024
          </div>
        </div>
      </div>
    );
  } else if (userData) {
    return (
      <div className={css.sidebar}>
        <div className={css.sidebarWrapper}></div>
        <div className={css.sidebarMenu}>
          <h5>..., XX anos</h5>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
