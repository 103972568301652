import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./UserPage.module.css";
import Head from "../../Utils/Head";
import { ClinicContext } from "../../Context/ClinicContext";
import { getUserByIdFirebase } from "../../Utils/firebaseClinic";

export const UserPage = () => {
  const { appointmentsDay, setSelectedPatient, selectedPatient } =
    useContext(ClinicContext);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getUserByIdFirebase(params.id).then(function (result) {
      setSelectedPatient(result[0]);
    });
  }, []);

  return (
    <section>
      <Head title="User" description="User - Mairini" />
      <h4>Dados Principais</h4>
      <h5>Nome: {selectedPatient.displayName}</h5>
      <h5>Idade: </h5>
      <h5>Sexo: </h5>
      <h5>Endereço: </h5>
      <h5>Email: {selectedPatient.email}</h5>

      <div></div>
    </section>
  );
};
