import React, { useContext } from "react";

import css from "./AdminPage.module.css";
import { ClinicContext } from "../../Context/ClinicContext";

export const AdminUsers = () => {
  const { users } = useContext(ClinicContext);
  return (
    <div>
      <h5>Usuários</h5>
      {users.map((u) => (
        <div className={css.card} key={u.id}>
          <h5>{u.displayName}</h5>
          <p>email: {u.email}</p>
          <p>categoria: {u.category}</p>
        </div>
      ))}
    </div>
  );
};
